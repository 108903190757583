// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-cerca-de-mi-jsx": () => import("./../../../src/pages/cerca-de-mi.jsx" /* webpackChunkName: "component---src-pages-cerca-de-mi-jsx" */),
  "component---src-pages-comprar-formulario-jsx": () => import("./../../../src/pages/comprar/formulario.jsx" /* webpackChunkName: "component---src-pages-comprar-formulario-jsx" */),
  "component---src-pages-comprar-index-jsx": () => import("./../../../src/pages/comprar/index.jsx" /* webpackChunkName: "component---src-pages-comprar-index-jsx" */),
  "component---src-pages-comprar-pago-completado-jsx": () => import("./../../../src/pages/comprar/pago-completado.jsx" /* webpackChunkName: "component---src-pages-comprar-pago-completado-jsx" */),
  "component---src-pages-comprar-pago-jsx": () => import("./../../../src/pages/comprar/pago.jsx" /* webpackChunkName: "component---src-pages-comprar-pago-jsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-jsx": () => import("./../../../src/pages/login.jsx" /* webpackChunkName: "component---src-pages-login-jsx" */),
  "component---src-pages-perfil-index-jsx": () => import("./../../../src/pages/perfil/index.jsx" /* webpackChunkName: "component---src-pages-perfil-index-jsx" */),
  "component---src-pages-productos-detalle-producto-jsx": () => import("./../../../src/pages/productos/DetalleProducto.jsx" /* webpackChunkName: "component---src-pages-productos-detalle-producto-jsx" */),
  "component---src-pages-productos-jsx": () => import("./../../../src/pages/productos.jsx" /* webpackChunkName: "component---src-pages-productos-jsx" */),
  "component---src-pages-productos-productos-jsx": () => import("./../../../src/pages/productos/Productos.jsx" /* webpackChunkName: "component---src-pages-productos-productos-jsx" */),
  "component---src-pages-recetas-detalle-receta-bk-jsx": () => import("./../../../src/pages/recetas/DetalleReceta_bk.jsx" /* webpackChunkName: "component---src-pages-recetas-detalle-receta-bk-jsx" */),
  "component---src-pages-recetas-detalle-receta-jsx": () => import("./../../../src/pages/recetas/DetalleReceta.jsx" /* webpackChunkName: "component---src-pages-recetas-detalle-receta-jsx" */),
  "component---src-pages-recetas-jsx": () => import("./../../../src/pages/recetas.jsx" /* webpackChunkName: "component---src-pages-recetas-jsx" */),
  "component---src-pages-recetas-recetas-bk-jsx": () => import("./../../../src/pages/recetas/Recetas_bk.jsx" /* webpackChunkName: "component---src-pages-recetas-recetas-bk-jsx" */),
  "component---src-pages-recetas-recetas-jsx": () => import("./../../../src/pages/recetas/Recetas.jsx" /* webpackChunkName: "component---src-pages-recetas-recetas-jsx" */),
  "component---src-pages-recover-password-jsx": () => import("./../../../src/pages/recover-password.jsx" /* webpackChunkName: "component---src-pages-recover-password-jsx" */),
  "component---src-pages-registrar-jsx": () => import("./../../../src/pages/registrar.jsx" /* webpackChunkName: "component---src-pages-registrar-jsx" */),
  "component---src-pages-registro-jsx": () => import("./../../../src/pages/registro.jsx" /* webpackChunkName: "component---src-pages-registro-jsx" */),
  "component---src-pages-reset-password-jsx": () => import("./../../../src/pages/reset-password.jsx" /* webpackChunkName: "component---src-pages-reset-password-jsx" */),
  "component---src-pages-sobre-nosotros-jsx": () => import("./../../../src/pages/sobre-nosotros.jsx" /* webpackChunkName: "component---src-pages-sobre-nosotros-jsx" */)
}

