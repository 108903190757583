/** @format */

import React, { createContext, useState } from "react";

export const AppContext = createContext(null);

export const ContextWrapper = (props) => {
  const [store, setStore] = useState({
    productos: {},
    usuarioAutenticado: { nombre: "" },
    envio: 0,
    carrito: {},
    tipoRecetaSeleccionada: {
      idTipoReceta: 0,
      descripcion: "Selecciona un tipo de receta",
    },
    tipoProductoSeleccionado: {
      idTipoProducto: 0,
      descripcion: "Selecciona un tipo de producto",
    },
    aprobacion: "",
    primeraVisita: true,
    codigoDescuento: "",
    porcentajeDescuento: 0.0,
  });

  const [actions] = useState({
    setSession: (sesion) => {
      setStore(sesion);
    },
    addTask: (producto, cantidad) => {
      setStore((store) => ({
        ...store,
        carrito: {
          ...store.carrito,
          [producto.idProducto]: {
            idProducto: producto.idProducto,
            cantidad: cantidad,
            nombreProducto: producto.nombreProducto,
            imagen: producto.url,
            precioDolares: producto.precioDolares,
            precio: producto.precio,
            precioQuetzales: producto.precioQuetzales,
            usa_cupon: producto.usa_cupon,
            enOferta: producto.enOferta,
          },
        },
      }));
    },
    obtenerTotal: (carrito, descuento = 0) => {
      let total = 0.0;
      let arreglo = Object.keys(carrito);
      for (let propiedad of arreglo) {
        if (carrito[propiedad].usa_cupon === 1) {
          total += carrito[propiedad].cantidad * carrito[propiedad].precioQuetzales * ((100 - descuento) / 100);
        } else {
          total += carrito[propiedad].cantidad * carrito[propiedad].precioQuetzales;
        }
      }
      return total;
    },
    //Se utilza para tener el valor de descuento del total de la factura.
    obtenerDescuentoTotal: (carrito, descuento = 0) => {
      let total = 0.0;
      let totalGeneral = 0.0;
      let arreglo = Object.keys(carrito);
      for (let propiedad of arreglo) {
        if (carrito[propiedad].usa_cupon === 1) {
          total += carrito[propiedad].cantidad * carrito[propiedad].precioQuetzales * ((100 - descuento) / 100);
        } else {
          total += carrito[propiedad].cantidad * carrito[propiedad].precioQuetzales;
        }
        totalGeneral += carrito[propiedad].cantidad * carrito[propiedad].precioQuetzales;
      }
      return totalGeneral - total;
    },
    obtenerTotalProductos: (carrito) => {
      let total = 0;
      if (carrito === null) return total;
      let arreglo = Object.keys(carrito);
      for (let propiedad of arreglo) {
        total += carrito[propiedad].cantidad;
      }
      return total;
    },
    addProductos: (productos) => {
      setStore((store) => ({ ...store, productos: productos }));
    },
    asignarUsuario: (usuario) => {
      setStore((store) => ({ ...store, usuarioAutenticado: usuario }));
      //localStorage.setItem("session_chapin", JSON.stringify(store));
    },
    asignarCodigo: (codigo, descuento) => {
      setStore((store) => ({ ...store, codigoDescuento: codigo, porcentajeDescuento: descuento }));
      //localStorage.setItem("session_chapin", JSON.stringify(store));
    },
    cerrarSesion: () => {
      setStore((store) => ({ ...store, usuarioAutenticado: { nombre: "" } }));
    },
    eliminarProducto: (carrito, producto) => {
      let carritoNuevo = {};
      Object.keys(carrito).forEach((key) => {
        if (carrito[key].idProducto === producto.idProducto) {
        } else carritoNuevo[key] = carrito[key];
      });
      setStore((store) => ({
        ...store,
        carrito: carritoNuevo,
      }));
    },
    guardarDatosEnvio: (datos) => {
      setStore((store) => ({ ...store, datosEnvio: datos }));
    },
    asignarEnvio: (valor) => {
      setStore((store) => ({ ...store, envio: valor }));
    },
    setTipoRecetaSeleccionada: (seleccion) => {
      setStore((store) => ({ ...store, tipoRecetaSeleccionada: seleccion }));
    },
    setTipoProductoSeleccionado: (seleccion) => {
      setStore((store) => ({ ...store, tipoProductoSeleccionado: seleccion }));
    },
    setAprobacion: (aprobacion) => {
      // limpia el codigo del pedido
      if (aprobacion === "") setStore((store) => ({ ...store, aprobacion: aprobacion }));
      // pedido exitoso, se limpia el carrito y el codigo de descuento
      else
        setStore((store) => ({
          ...store,
          aprobacion: aprobacion,
          carrito: {},
          usuarioAutenticado: { ...store.usuarioAutenticado, codigo: "" },
          codigoDescuento: "",
          porcentajeDescuento: 0.0,
        }));
    },

    incrementarProducto: (producto) => {
      setStore((store) => ({
        ...store,
        carrito: {
          ...store.carrito,
          [producto.idProducto]: {
            ...producto,
            cantidad: producto.cantidad + 1,
          },
        },
      }));
    },
    decrementarProducto: (producto) => {
      if (producto?.cantidad > 1)
        setStore((store) => ({
          ...store,
          carrito: {
            ...store.carrito,
            [producto.idProducto]: {
              ...producto,
              cantidad: producto.cantidad - 1,
            },
          },
        }));
    },
    setSid: (sid) => {
      setStore((store) => ({ ...store, sid: sid }));
    },
    setPrimeraVisita: () => {
      setStore((store) => ({ ...store, primeraVisita: false }));
    },
  });

  return <AppContext.Provider value={{ store, actions }}>{props.children}</AppContext.Provider>;
};
